// import { mapActions } from 'vuex'
import { ServiceFactory } from '@/services/ServiceFactory'
// import moment from 'moment'
import Chart from 'chart.js'
// import ZbNotificationsChart from '../notification-detail'
const _notificationService = ServiceFactory.get('NotificationsService')
const _clientSearch = ServiceFactory.get('ClientSearchService')
export default {
  // components: {
  //   ZbNotificationsChart
  // },
  name: 'ZbNotifications',
  data: () => ({
    notification: {
      title: '',
      message: '',
      filter: [],
      date: '',
      time: '',
      sendingType: ''
    },
    loading: false,
    dialog: false,
    setNotification: 'inmediately',
    menu: false,
    menu2: false,
    showCalendar: false,
    showFilters: false,
    headers: [
      { text: 'Titulo', value: 'title', align: 'start', sortable: false },
      { text: 'Descripción', value: 'body' },
      { text: 'Enviado a:', value: 'sendTo' },
      { text: 'Enviado:', value: 'sendingType' },
      { text: 'Fecha', value: 'dateTime' },
      { text: 'Opciones', value: 'options' }
      // { text: '', value: 'data-table-expand' }
    ],
    expanded: [],
    singleExpand: true,
    notifications: [],
    selectSendTo: 1,
    peopleList: [],
    selectedPeople: [],
    search: null,
    chartPerf: null,
    chartOs: null,
    perfData: {},
    osData: {},
    loadingChart: false,
    successful: 0,
    converted: 0,
    errored: 0,
    failed: 0,
    total: 0,
    ios: 0,
    android: 0,
    flagSelected: []
  }),
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    async search (val) {
      console.log(this.selectedPeople, val)
      try {
        const searchResp = await _clientSearch.search(val)
        console.log(searchResp)
        var l1 = searchResp.clients
        // this.peopleList = searchResp.clients
        var l2 = l1.concat(this.flagSelected)
        this.peopleList = l2
      } catch (error) {
        console.log(error)
      }
    }
    // expanded: async function () {
    //   this.successful = 0
    //   this.converted = 0
    //   this.errored = 0
    //   this.failed = 0
    //   this.total = 0
    //   this.ios = 0
    //   this.android = 0
    //   if (this.expanded.length > 0) {
    //     // console.log('expanded')
    //     this.loadingChart = true
    //     if (this.chartPerf) {
    //       this.chartPerf.destroy()
    //     }
    //     if (this.chartOs) {
    //       this.chartOs.destroy()
    //     }
    //     console.log(this.expanded)
    //     try {
    //       var servStatsResp = await _notificationService.singleNotificationStats(this.expanded[0].zbNotificationId)
    //       this.loadingChart = false
    //       console.log(servStatsResp)
    //       setTimeout(() => {
    //         if (servStatsResp.ios) {
    //           // console.log('IOS')
    //           this.successful = this.successful + servStatsResp.ios.successful
    //           this.converted = this.converted + servStatsResp.ios.converted
    //           this.failed = this.failed + servStatsResp.ios.failed
    //           this.errored = this.errored + servStatsResp.ios.errored
    //           this.ios = servStatsResp.ios.successful
    //           this.total = this.total + this.ios
    //         }
    //         if (servStatsResp.android) {
    //           // console.log('Android')
    //           this.successful = this.successful + servStatsResp.android.successful
    //           this.converted = this.converted + servStatsResp.android.converted
    //           this.failed = this.failed + servStatsResp.android.failed
    //           this.errored = this.errored + servStatsResp.android.errored
    //           this.android = servStatsResp.android.successful
    //           this.total = this.total + this.android
    //         }
    //         this.perfData = {
    //           labels: ['Exitoso', 'Falló', 'Error'], // servStatsResp.dataset.labels,
    //           datasets: [
    //             {
    //               label: 'Perf',
    //               data: [this.successful, this.failed, this.errored], // servStatsResp.dataset.data,
    //               backgroundColor: ['Green', 'Orange', 'Red'],
    //               hoverBackgroundColor: ['#388E3C', '#F57C00', '#D32F2F']
    //               // backgroundColor: chartDatasetsBackground
    //             }
    //           ]
    //         }
    //         var chId = 'chart_perf_' + this.expanded[0].zbNotificationId
    //         var canvas = document.getElementById(chId).getContext('2d')
    //         this.chartPerf = new Chart(canvas, {
    //           type: 'pie',
    //           data: this.perfData,
    //           options: {
    //             title: {
    //               display: true,
    //               text: 'Rendimiento'
    //             },
    //             responsive: true,
    //             legend: { display: false }
    //           }
    //         })

    //         this.osData = {
    //           labels: ['Android', 'iOS'], // servStatsResp.os.labels,
    //           datasets: [
    //             {
    //               label: 'OS',
    //               data: [this.android, this.ios], // servStatsResp.os.data,
    //               backgroundColor: ['Green', 'black'],
    //               hoverBackgroundColor: ['#388E3C', '#212121']
    //             }
    //           ]
    //         }
    //         var chId2 = 'chart_os_' + this.expanded[0].zbNotificationId
    //         var canvas2 = document.getElementById(chId2).getContext('2d')
    //         // var canvas2 = document.getElementById('chartPie').getContext('2d')
    //         this.chartOs = new Chart(canvas2, {
    //           type: 'pie',
    //           data: this.osData,
    //           options: {
    //             title: {
    //               display: true,
    //               text: 'Sistema operativo'
    //             },
    //             responsive: true,
    //             legend: { display: false }
    //           }
    //         })
    //       }, 1000)
    //     } catch (error) {
    //       this.loadingChart = false
    //       console.log(error)
    //       this.expanded = []
    //       this.chartPerf = null
    //       this.chartOs = null
    //       this.$notify({
    //         title: 'Error:',
    //         type: 'error',
    //         text: error.msg
    //       })
    //     }
    //   } else {
    //     // console.log('unexpanded')
    //     this.chartPerf = null
    //     this.chartOs = null
    //   }
    // }
  },
  mounted () {
    // this.$refs.calendar.scrollToTime('08:00')
    this.initialize()
  },
  methods: {
    async initialize () {
      try {
        const notificationsResponse = await _notificationService.index()
        console.log(notificationsResponse)
        this.notifications = notificationsResponse.notifications
      } catch (error) {
        console.log(error)
      }
      // try {
      // } catch (error) {
      // }
    },
    async sendNotfication () {
      this.loading = true
      this.$swal({
        title: 'Se enviará la notificación',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, enviar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading = false
          this.dialog = false
          if (this.showFilters) {
            this.notification.sendTo = this.notification.filter
          } else {
            this.notification.sendTo = ['all']
          }
          // this.notification.sendTo = this.notification.filter
          if (this.setNotification === 'inmediately') {
            // var now = moment()
            console.log('inmediato')
            this.notification.dateTime = ''
          } else {
            this.notification.dateTime = this.notification.date + ' ' + this.notification.time
            console.log('programado')
          }
          this.notification.sendingType = this.setNotification
          console.log(this.notification)
          try {
            if (this.selectSendTo === 1) {
              this.notification.zbUserIds = this.selectedPeople
              console.log(this.notification)
              await _notificationService.sendToPeople(this.notification)
              this.selectedPeople = []
            } else {
              await _notificationService.send(this.notification)
            }
            this.$swal(
              'Notificacion enviada',
              'Se envió correctamente',
              'success'
            ).then(async (result) => {
              this.initialize()
              this.notification = {
                title: '',
                message: '',
                filter: [],
                date: '',
                time: '',
                sendingType: ''
              }
              this.setNotification = 'inmediately'
            })
          } catch (error) {
            this.$notify({
              title: 'Error:',
              type: 'error',
              text: error.message + ', no se pudo enviar.'
            })
          }
        } else {
          this.loading = false
          this.dialog = false
        }
      })
    },
    showPicker () {
      if (this.setNotification === 'inmediately') {
        this.showCalendar = false
      } else {
        this.showCalendar = true
      }
    },
    changeFilter () {
      console.log('change showFilters value')
      if (this.selectSendTo === 3) {
        this.showFilters = true
      } else {
        this.showFilters = false
      }
    },
    viewDetail (id) {
      console.log(id)
      this.$router.push({ name: 'notif-detail', params: { id } })
    },
    changeAutocomplete () {
      console.log(this.selectedPeople)
      console.log(this.peopleList)
      const usersSelected = []
      const lastUser = this.selectedPeople[this.selectedPeople.length - 1]
      console.log(lastUser)
      var res = null
      // this.peopleList.forEach(el => {
      res = this.peopleList.find(people => people.zbUserId === lastUser)
      console.log(res)
      this.flagSelected.push(res)
      console.log(this.flagSelected)
      // })
    }
    // async initialize () {},
    // async submitData () {
    //   this.loading = true
    //   if (this.imageBanner && this.imageBanner[0]) {
    //     const newImage = new FormData()
    //     newImage.append('image', this.imageBanner[0])
    //     console.log(this.imageBanner)
    //     try {
    //       if (this.submitOption === 'create') {
    //         // await _adsBannerService.newAdBanner(this.postData, newImage)
    //         this.$notify({
    //           type: 'success',
    //           text: 'Imagen de banner agregado correctamente'
    //         })
    //       } else {
    //         // await _adsBannerService.editAdBanner(this.postData, newImage)
    //         this.$notify({
    //           type: 'success',
    //           text: 'Imagen de banner editada correctamente'
    //         })
    //       }
    //       this.loading = false
    //       this.dialog = false
    //       this.initialize()
    //     } catch (error) {
    //       this.loading = false
    //       // console.log(error)
    //       this.$notify({
    //         type: 'error',
    //         text: error.msg
    //       })
    //     }
    //   }
    // },
  }
}
